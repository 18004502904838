import { Modal, Button, Row, Col, Input, Select, DatePicker, message } from "antd"
import { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Language } from "../../App";
import { http } from "../../helpers/http";
import icAddGroup from "../../img/ic-add-group.svg";
import { useTranslation } from 'react-i18next';

type CreateStudyGroupModalProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
    refreshStudyGroups: () => void;
    languages: Language[]
};

type GroupTemplate = {
    id: number | null,
    start_date: string | null,
    csdd_nr: string | null,
    name?: string,
    // language_id?: number | null,
    planned_slots?: number | null
}

export const CreateStudyGroupModal = ({ open, setOpen, refreshStudyGroups, languages }: CreateStudyGroupModalProps) => {
    const { t } = useTranslation();
    const [templates, setTemplates] = useState<GroupTemplate[] | []>([]);
    const [newGroup, setNewGroup] = useState<GroupTemplate>({
        id: null,
        start_date: null,
        csdd_nr: null,
        // language_id: null,
        planned_slots: 30
    });
    const [loading, setLoading] = useState(false);

    const getTemplates = () => {
        http.get(`study-group-templates`).then((res: AxiosResponse) => {
            const { data } = res.data;
            if (data.length === 0) return;
            const templates: GroupTemplate[] = data.map((temp: GroupTemplate): GroupTemplate => {
                return {
                    id: temp.id,
                    csdd_nr: temp.csdd_nr,
                    start_date: temp.start_date,
                    name: temp.name
                }
            })
            setTemplates(templates);
        })
    };

    const resetModal = () => {
        setOpen(false);
        setNewGroup({
            id: null,
            start_date: null,
            csdd_nr: null,
            // language_id: null,
            planned_slots: 30,
        })
    };

    const createGroup = () => {
        setLoading(true);
        http.post(`create-study-group/${newGroup.id}`, { ...newGroup }).then((res: AxiosResponse) => {
            setLoading(false);
            resetModal();
            refreshStudyGroups();
        }).catch((err: AxiosError) => {
            setLoading(false);
            message.error(t("errors.error"))
        })
    }

    useEffect(() => {
        if (open) getTemplates();
    }, [open]);

    return <Modal
        open={open}
        onCancel={() => resetModal()}
        destroyOnClose={true}
        footer={<Button
            type="primary"
            style={{ width: "100%" }}
            onClick={createGroup}
            disabled={!newGroup.id || !newGroup.csdd_nr || !newGroup.start_date}
            loading={loading}
        >
            {t("groups.create_group")}
        </Button>}
        width={320}
    >
        <Row>
            <Col span={24} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20 }}>
                <div style={{ marginBottom: 10 }}>
                    <img src={icAddGroup} />
                </div>
                <span style={{ fontSize: 20, color: "#303136", textAlign: "center" }}>
                    {t("groups.create_group")}
                </span>
            </Col>
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("groups.group_csdd_code")}</p>
            <Input
                style={{ marginBottom: 20 }}
                onChange={(e) => {
                    setNewGroup((prev: GroupTemplate): GroupTemplate => {
                        return {
                            ...prev,
                            csdd_nr: e.target.value.length > 0 ? e.target.value : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.category")}</p>
            <Select
                style={{ width: "100%", marginBottom: 20 }}
                onChange={(e: number) => {
                    setNewGroup((prev: GroupTemplate): GroupTemplate => {
                        return {
                            ...prev,
                            id: e
                        }
                    })
                }}
            >
                {
                    templates.map((template: GroupTemplate) => {
                        return <Select.Option key={template.id} value={template.id}>{template.name}</Select.Option>
                    })
                }
            </Select>
            {/* <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12, textAlign: "left" }}>{t("common.language")}</p>
            <Select
                style={{ width: "100%", marginBottom: 20 }}
                value={1}
                onChange={e => {
                    setNewGroup((prev: GroupTemplate): GroupTemplate => {
                        return {
                            ...prev,
                            language_id: e
                        }
                    })
                }}
                // disabled
            >
                {
                    languages.map((language: Language) => {
                        return <Select.Option key={language.id} value={language.id}>{language.iso?.toUpperCase()}</Select.Option>
                    })
                }
            </Select> */}
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("common.beginning")}</p>
            <DatePicker
                format={"DD.MM.YYYY"}
                style={{ width: "100%", marginBottom: 15 }}
                onChange={(e, date: string) => {
                    setNewGroup((prev: GroupTemplate): GroupTemplate => {
                        return {
                            ...prev,
                            start_date: date ? moment(date, "DD.MM.YYYY").format("YYYY-MM-DD") : null
                        }
                    })
                }}
            />
            <p style={{ marginTop: 0, marginBottom: 6, fontSize: 12 }}>{t("groups.students_count")}</p>
            <Input

                style={{ marginBottom: 20 }}
                type="number"
                value={newGroup.planned_slots ?? 1}
                min={0}
                step={1}
                onChange={(e) => {
                    setNewGroup((prev: GroupTemplate): GroupTemplate => {
                        return {
                            ...prev,
                            planned_slots: e.target.value.length > 0 ? Number(e.target.value) : 1
                        }
                    })
                }}
            />
        </Row>
    </Modal>
}